import React, { useState } from 'react';
import { Link, PageProps, graphql } from 'gatsby';
import SearchInput from '../components/base/SearchInput/SearchInput';
import { ILink } from '../models/ILink';
import { getDefaultSEO } from '../utils';
import Seo from '../components/Seo';
import HTPageWrapper from '../wrappers/HTPageWrapper';

interface IPageNotFoundProps {
  recentPosts: Queries.WpPostConnection;
  mostUsedCategories: Queries.WpCategoryConnection;
  allWpTag: Queries.WpTagConnection;
  allWpPost: Queries.WpPostConnection;
}

interface IMostUsedCategory {
  text: string;
  link: string;
  count: number;
  subItems: IMostUsedCategory[];
}

const PageNotFound = ({ data, location }: PageProps<IPageNotFoundProps>) => {
  const { recentPosts, mostUsedCategories } = data;
  const [searchText, setSearchText] = useState('');

  const posts: ILink[] =
    recentPosts.nodes.map((node) => ({
      text: node.title || '',
      link: node.uri || '#',
    })) || [];

  const categories: IMostUsedCategory[] = [];

  mostUsedCategories.nodes.forEach((node) => {
    const parentIndex = categories.findIndex((category) => node.uri?.includes(category.link || ''));
    const item: IMostUsedCategory = {
      link: node.uri || '#',
      text: node.name || '',
      count: node.count || 0,
      subItems: [],
    };
    if (parentIndex !== -1) {
      const category = categories[parentIndex];

      if (category) {
        categories[parentIndex] = {
          ...category,
          subItems: [...category.subItems, item],
        };
      }
    } else {
      categories.push(item);
    }
  });

  const executeSearch = () => {};

  const renderCategory = (category: IMostUsedCategory) => (
    <>
      <Link to={category.link}>{category.text}</Link>
      <span>({category.count})</span>
    </>
  );

  return (
    <HTPageWrapper category='Landing Page' title='Page not found' location={location}><div className="page-not-found">
      <div className="container">
        <h2 className="page-not-found__title">Oops! That page can’t be found.</h2>
        <p>
          It looks like nothing was found at this location. Maybe try one of the links below or a
          search?
        </p>
        <div className="page-not-found__search-form">
          <SearchInput
            executeSearch={executeSearch}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </div>
        <h3>Recent Posts</h3>
        <ul className="page-not-found__recent-posts">
          {posts.map((post, index) => (
            <li key={index}>
              <Link to={post.link || '#'}>{post.text}</Link>
            </li>
          ))}
        </ul>
        <h3>Most Used Categories</h3>
        <ul>
          {categories.map((category, index) => (
            <li key={`main-${index}`}>
              {renderCategory(category)}
              <ul>
                {category.subItems.map((subCategory, index) => (
                  <li key={`sub-${index}`}>{renderCategory(subCategory)}</li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
    </HTPageWrapper>
  );
};

export default PageNotFound;

export const Head = ({ location }: PageProps) => {
  const pageTitle = 'Page Not Found - UpgradedPoints.com';
  const socialTitle = 'Page Not Found - UpgradedPoints.com';
  const seo = getDefaultSEO(location.pathname, pageTitle, socialTitle, '');
  return <Seo title={pageTitle} wpSeo={seo} />;
};

export const query = graphql`query PageNotFoundQuery {
  recentPosts: allWpPost(sort: {date: DESC}, limit: 5) {
    nodes {
      title
      uri
    }
  }
  mostUsedCategories: allWpCategory(
    filter: {count: {gt: 50}}
    sort: {count: DESC}
  ) {
    nodes {
      name
      count
      uri
    }
  }
}`;
